import React, { Component } from 'react';
import { Link } from 'gatsby';
import Marquee from 'react-marquee';
import { ParallaxProvider } from 'react-scroll-parallax';
import PageTransition from 'gatsby-plugin-page-transitions';import Navigation from "../components/Header/Navigation.js";
import PageBreak from "../components/repeating_elements/page_break.js";
import { ArrowLink } from "../components/buttons/Arrow_Link.js";
import replace_url from "../components/utilities/replace_url.js";
import LazyLoad from "../components/repeating_elements/Lazy_Load.js";
import BackgroundImage from "../components/repeating_elements/Background-Image.js";
import Image from "../components/repeating_elements/Image.js";
import PeripheralList from "../components/repeating_elements/Peripheral-List.js";
import {HeaderOne, HeaderTwo, BodyCopy, HeaderEyebrow} from "../components/repeating_elements/Typography.js";
import ImageFeed from "../components/Culture/Image_Feed.js";
import GetInTouchFooter from "../components/Global/Get_In_Touch_Footer.js";
import Footer from "../components/Footer/Footer.js";
import WorkWithUs from "../components/Global/Work_With_Us_Button.js";
import GDPR from '../components/Global/GDPR';
// import Loader from '../components/Global/Loader';
import SEO from '../components/seo.js';


class GalleryImage extends Component {

	render(){
		return(
			<LazyLoad>
				<div className="photo-contain">
					<BackgroundImage url={this.props.url}></BackgroundImage>
				</div>
			</LazyLoad>
		)
	}
}

class Culture extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLoaded: this.props.pageContext.isLoaded,
			isLazyLoaded: this.props.pageContext.isLazyLoaded,
			isReady: this.props.pageContext.isReady,
			data: this.props.pageContext.data,
			cookieBanner: this.props.pageContext.cookieBanner,
			headerLinks: this.props.pageContext.header,
			footerLinks: this.props.pageContext.footer,
		}
		this.create_gallery_section = this.create_gallery_section.bind(this);
		this.create_featured_section = this.create_featured_section.bind(this);
		this.create_updates_section = this.create_updates_section.bind(this);
		this.create_marquee_section = this.create_marquee_section.bind(this);
	}
	componentDidMount() {

	}
	create_gallery_section(data){
		var column_one = [];
		var column_two = [];
		var i = 1;

		column_one.push(
			<div key="module-text-display" className="module text-display">
				<HeaderOne title={data.h_title} />
				<BodyCopy>
					<p>{data.h_description}</p>
					<a className="button" href={data.h_cta.url} target="_blank" rel="noopener noreferrer">{data.h_cta.title}</a>
				</BodyCopy>

			</div>
		)

		while(i < 6){
			let column = null;
			if(i > 2){
				column = column_two;
			} else{
				column = column_one;
			}
			column.push(
				<div key={"module-photo-"+i} className={"module photo photo-"+i}>
					<GalleryImage url={data["h_gallery_image_"+i]}></GalleryImage>
				</div>
			)
			i++;
		}

		return <div className="grid gallery">
			<div className="grid-m-6 grid-t-6 grid-d-6">{column_one}</div>
			<div className="grid-m-6 push-t-1 grid-t-8 push-d-1 grid-d-8">{column_two}</div>
		</div>;
	}
	create_featured_section(data){
		var element = [];
		element.push(
			<div className="grid-m-6 grid-t-6 grid-d-6 content-section" key={`${data.f_title}00`}>
				<HeaderEyebrow title="From the journal" />
				<HeaderTwo title={data.f_title} />
				<BodyCopy>
					<p>{data.f_description}</p>
				</BodyCopy>
				<ArrowLink title="Read more" url={replace_url(data.f_post.url)} />
			</div>
		);
		element.push(
			<div className="grid-m-6 grid-t-10 grid-d-10 photo-section" key={`${data.f_title}01`}>
				<LazyLoad>
					<Link to={replace_url(data.f_post.url)}>
						<div className="photo">
							<Image url={data.f_image} />
						</div>
					</Link>
				</LazyLoad>
			</div>
		);
		return <div className="grid featured-section">{element}</div>;
	}
	create_updates_section(data){
		let large_press_items = [];
		let small_press_items = [];
		data.journal_items.forEach((i,x)=>{
			var classnames = "grid-m-6 grid-t-5 grid-d-5 press-item";
			var image_element = <div className="img">
					<BackgroundImage url={i.image} key={`${i.descriptio}00`} />
			</div>;
			var element = <div key={x} className={classnames}>
				<a href={i.link.replace('api.thecharlesnyc.com', 'thecharlesnyc.com')} title={i.title}>
					{ image_element }
					<BodyCopy key={`${i.descriptio}01`}>
						<p className="title">{i.title}</p>
					</BodyCopy>
				</a>
			</div>;
			large_press_items.push(element)
		})
		data.updates_items.forEach((i,x)=>{
			var classnames = "press-item";
			var element = <div key={x} className={classnames}>
				<BodyCopy key={`${i.descriptio}02`}>
					<p className="body-copy"><a href={i.link.replace('api.thecharlesnyc.com', 'thecharlesnyc.com')}>{i.description}</a></p>
				</BodyCopy>
			</div>;
			small_press_items.push(element);
		})
		return (
			<React.Fragment>
				{large_press_items}
				<div className="grid-m-6 grid-t-6 grid-d-6 press-col">
					{small_press_items}
				</div>
			</React.Fragment>
		);
	}
	create_marquee_section(data){
		var element = [];
		data.marquee.forEach((i,x) => {
			element.push(<span key={"marquee"+x} className="marquee"><Link to={i.url.replace('api.thecharlesnyc.com', 'thecharlesnyc.com')} title={i.text}>{i.text}</Link></span>)
		});
		data.marquee.forEach((i,x) => {
			element.push(<span key={"marquee"+x} className="marquee"><Link to={i.url.replace('api.thecharlesnyc.com', 'thecharlesnyc.com')} title={i.text}>{i.text}</Link></span>)
		});
		data.marquee.forEach((i,x) => {
			element.push(<span key={"marquee"+x} className="marquee"><Link to={i.url.replace('api.thecharlesnyc.com', 'thecharlesnyc.com')} title={i.text}>{i.text}</Link></span>)
		});
		return <Marquee text={element} loop={true} hoverToStop={true} />;
	}
	render() {
		let page_ready = this.state.isLoaded && this.state.isReady ? "page-ready page-loaded show-text" : "page-loading";
		let data = this.state.data;
		let culture_data = null;
		let about_data = null;
		if(data){
			data = this.state.data;
			culture_data = data[0].acf;
			about_data = data[1].acf;

			return (
                <ParallaxProvider><PageTransition>
				<GDPR data={this.state.cookieBanner} />

				<SEO
					title={this.state.data[0].yoast_meta.yoast_wpseo_title}
					description={this.state.data[0].yoast_meta.yoast_wpseo_metadesc}
				/>
				<div className="site-contain">
					<Navigation links={this.state.headerLinks} />
					  <div className="page-main">
                    <WorkWithUs />
                        <div className={"page culture-page " + page_ready}>
                            <div className="loading-screen"></div>
                            {this.create_gallery_section(culture_data)}
                            <ImageFeed data={culture_data} />
                            <LazyLoad>
                                {this.create_featured_section(culture_data)}
                            </LazyLoad>
                            <PageBreak className="peripheral-top" />
                            <LazyLoad>
                                <div className="grid list peripheral-list-title">
                                    <h5 className="grid-m-6 grid-t-16 grid-d-16 styling-eyebrow">Our Initiatives</h5>
                                    <PeripheralList slides={about_data.peripherals} />
                                </div>
                            </LazyLoad>
                            <PageBreak />
                            <LazyLoad>
                                <div className="grid">
                                    <h5 className="grid-m-6 grid-t-16 grid-d-16 styling-eyebrow">Updates</h5>
                                </div>
                                <div className="grid press-grid">
                                    {this.create_updates_section(culture_data)}
                                </div>
                            </LazyLoad>
                            <GetInTouchFooter data={this.state.data[0]} />
                            <Footer links={this.state.footerLinks} />
                        </div>
					</div>
				</div>
			</PageTransition></ParallaxProvider>
			)
		} else{

			return (
				<div></div>
			)
		}
	}
}

export default Culture;
