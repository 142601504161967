import React, { Component } from "react";
import LazyLoad from 'react-lazyload';
import config from '../../config.js';

const Image = ({url, alt}) => (
	<LazyLoad offset={config.loadOffset} once>
		<img src={url} alt={alt} loading="lazy"/>
	</LazyLoad>
);

export default Image;
