import React, { Component } from 'react';
// import { Link } from 'gatsby';
import Slider from "react-slick";

import { TimelineMax, TweenMax, Power1 } from 'gsap'
import 'intersection-observer';
import { InView } from 'react-intersection-observer'

import { ArrowLink } from "../buttons/Arrow_Link.js";
import Image from "./Image.js";



export default class PeripheralList extends Component {
	constructor(props){
		super(props);
		this.peripheral_slider_settings = {
			adaptiveHeight: true,
			arrows: false,
			dots: false,
			infinite: false,
			speed: 500,
			slide: ".slide",
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						dots: false,
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true
					}
				}
			]
		}
		this.state = {
			currentSlide: 1,
			totalSlides: this.props.slides.length,
		};
		this.update_info = this.update_info.bind(this);
	}
	update_info(){
		this.setState({
			currentSlide: this.slider.slickCurrentSlide,
			totalSlides: this.slider.slickTotalSlides,
		})
	}
	handleInView(inView) {
		const slides = document.querySelectorAll('.slide')
		TweenMax.staggerTo(slides, 0, { y: '150px', opacity: 0 })
		if (inView) {
			const timelime = new TimelineMax()
			timelime
				.staggerTo(slides, .4, { y: '0px', opacity: 1, ease: Power1.easeOut }, '0.2', '0.5')
		}
	}
	render() {
		return (
			<InView as="div" triggerOnce={true} onChange={inView => this.handleInView(inView)} className="row list peripheral-list">
				<Slider {...this.peripheral_slider_settings}>
					{
						(()=>{
							let slides = [];
							this.props.slides.forEach((i, x)=>{
								slides.push(
									<div key={"peripheral-"+x} className="grid-m-1 grid-t-1 grid-d-1 slide item">
										<div className="item-logo"><Image url={i.peripheral_logo} alt={i.peripheral_name} /></div>
										<div className="item-copy">
											<p>{i.peripheral_description}</p>
											<ArrowLink title={i.peripheral_cta_copy} url={i.peripheral_cta_url} />
											<p className="numbers-copy">{x+1}/{this.state.totalSlides}</p>
										</div>
									</div>
								);
							})
							return slides;
						})()
					}
				</Slider>
			</InView>
		)
	}
}
