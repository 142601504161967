var urls = [
	"http://localhost:8080",
	"http://charlesnyc.stage.charlesgroup.thecharlescreative.com",
	"https://charlesnyc.stage.charlesgroup.thecharlescreative.com",
	"http://thecharlesnyc.com/",
	"https://thecharlesnyc.com/",
	"https://api.thecharlesnyc.com/"
];
export default function replace_url(url){
	urls.forEach(function(i){
		url = url.replace(i,"")
	})
	return url;
}
