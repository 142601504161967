import React, { Component } from "react";
import Slider from "react-slick";

import BackgroundVideo from "../repeating_elements/background_video.js";
import BackgroundImage from "../repeating_elements/Background-Image.js";
import LazyLoad from "../repeating_elements/Lazy_Load.js";

export default class ImageFeed extends Component {
	constructor(props){
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="grid culture-sliders">
				<div className="grid-m-6 grid-t-1=3 grid-d-1-3 tumblr-items">
					<div className="tumblr-posts-contain">
						<BackgroundImage url={this.props.data.team_image_1} loading="lazy"></BackgroundImage>
					</div>
				</div>
				<div className="grid-m-6 grid-t-1-3 grid-d-1-3 tumblr-items">
					<div className="tumblr-posts-contain">
						<BackgroundImage url={this.props.data.team_image_2} loading="lazy"></BackgroundImage>
					</div>
				</div>
				<div className="grid-m-6 grid-t-1-3 grid-d-1-3 tumblr-items">
					<div className="tumblr-posts-contain">
						<BackgroundImage url={this.props.data.team_image_3}></BackgroundImage>
					</div>
				</div>
				<div className="grid-m-6 grid-t-5 grid-d-5 tumblr-items">
					<div className="tumblr-posts-contain">
						<BackgroundImage url={this.props.data.team_image_4}></BackgroundImage>
					</div>
				</div>
				<div className="grid-m-6 grid-t-6 grid-d-6 tumblr-items tumblr-items--text">
					<div className="tumblr-posts-contain">
						<div className="tumblr-text">
							<h3 className="styling-eyebrow">{this.props.data.top_small_title}</h3>
							<h2 style={{margin: "10px 0"}} className="styling-header-2">{this.props.data.top_large_title}</h2>
							<p className="styling-body">{this.props.data.top_paragraph}</p>
						</div>
					</div>
				</div>
				<div className="grid-m-6 grid-t-5 grid-d-5 tumblr-items">
					<div className="tumblr-posts-contain">
						<BackgroundImage url={this.props.data.team_image_5}></BackgroundImage>
					</div>
				</div>
				<div className="grid-m-6 grid-t-12 grid-d-12 tumblr-items">
					<div className="tumblr-posts-contain">
						<BackgroundImage url={this.props.data.team_image_6}></BackgroundImage>
					</div>
				</div>
				<div className="grid-m-6 grid-t-4 grid-d-4 tumblr-items">
					<div className="tumblr-posts-contain">
						<BackgroundImage url={this.props.data.team_image_7}></BackgroundImage>
					</div>
				</div>
				<div className="grid-m-6 grid-t-5 grid-d-5 tumblr-items tumblr-items--text">
					<div className="tumblr-posts-contain">
						<div className="tumblr-text">
							<h2 style={{margin: "10px 0"}} className="styling-header-2">{this.props.data.bottom_large_title}</h2>
							<p className="styling-body">{this.props.data.bottom_paragraph}</p>
						</div>
					</div>
				</div>
				<div className="grid-m-6 grid-t-6 grid-d-6 tumblr-items">
					<div className="tumblr-posts-contain">
						<BackgroundImage url={this.props.data.team_image_8}></BackgroundImage>
					</div>
				</div>
				<div className="grid-m-6 grid-t-5 grid-d-5 tumblr-items">
					<div className="tumblr-posts-contain">
						<BackgroundImage url={this.props.data.team_image_9}></BackgroundImage>
					</div>
				</div>
			</div>
		);
	}
}
